import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>Keep It Simple</Subtitle>
      <SectionTitle>Complete Solution</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Cross Platform</FeatureTitle>
          <FeatureText>
            PC, Mac, Linux, Web, iOS, Android
          </FeatureText>
          <FeatureText>
            Collect data with your offline mobile device, finish up at your desk
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Creation</FeatureTitle>
          <FeatureText>Double the speed that your instructions are generated</FeatureText>
          <FeatureText>No more formatting excel files or word docs, beautiful instructions out of the box</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Document Control</FeatureTitle>
          <FeatureText>Automate and customize your document approval process</FeatureText>
          <FeatureText>Document updates are easy and seamless</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Communication</FeatureTitle>
          <FeatureText>Operator feedback portal keeps your instructions up to date and heightens morale</FeatureText>
          <FeatureText>Decrease quality issues by ensuring all required operators are notified of changes to the process</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Training</FeatureTitle>
          <FeatureText>Straightforward and intuitive interface ensure a smooth onboarding process</FeatureText>
          <FeatureText>Integrated operator training matrix to track your workforce flexibility</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Process Centric</FeatureTitle>
          <FeatureText>Deliver context aware instructions to operators based on where they are working in the shop</FeatureText>
          <FeatureText>Tie relevant process data to an instruction; recipes, cnc program #'s...</FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
